import React from "react"
import { Routes, Route } from "react-router-dom"
import ProtectedRoutes from "./ProtectedRoutes"
import Login from "pages/Login"
import ForgetPassword from "pages/ForgetPassword"
import PasswordSent from "pages/PasswordSent"
import ResetPassword from "pages/ResetPassword"
import Dashboard from "pages/Dashboard"
import Test from "pages/Test/Test"
import AccountDetails from "common/components/templates/AccountDetails/AccountDetails"
import BikeDashboard from "pages/BikeDashboard"
import UserDashboard from "pages/UserDashboard"
import InsightDashboard from "pages/InsightDashboard"
import { BikeDetails } from "common/components/templates/BikeDetails/BikeDetails"
import OTP from "pages/OTP/OTP"

type RouterProps = {
  domain: string
}

export const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <UserDashboard />
          </ProtectedRoutes>
        }
      />

      <Route path="/login" element={<Login />} />
      <Route path="/test" element={<Test />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/password-sent" element={<PasswordSent />} />
      <Route path="/otp-verify" element={<OTP />} />

      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/user-list"
        element={
          <ProtectedRoutes>
            <UserDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/user-list/:id`}
        element={
          <ProtectedRoutes>
            <AccountDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path={`/bike-list/:id`}
        element={
          <ProtectedRoutes>
            <BikeDetails />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/bike-list"
        element={
          <ProtectedRoutes>
            <BikeDashboard />
          </ProtectedRoutes>
        }
      />
      <Route
        path="/insight"
        element={
          <ProtectedRoutes>
            {/* <h1>Insight</h1> */}
            <InsightDashboard />
          </ProtectedRoutes>
        }
      />

      <Route path="*" element={<h1>Page not found</h1>} />
    </Routes>
  )
}
