import React, { useEffect } from "react"
import useError from "common/hooks/useErrors"
import useAuthStore from "store/authStore"
import Button from "common/components/atoms/Button"
import { createColumnHelper } from "@tanstack/react-table"
import styles from "./Dashboard.module.scss"
import Loader from "common/components/atoms/Loader"
import AddBikeModal from "common/components/templates/AddBikeModal/AddBikeModal"
import ConfirmAddBikeModal from "common/components/templates/ConfirmAddBikeModal/ConfirmAddBikeModal"
import BikeFilterModal from "common/components/templates/BikeFilterModal/BikeFilterModal"
import PrefecturesFilterModal from "common/components/templates/PrefecturesFilterModal/PrefecturesFilterModal"
import DeactivateAgencyModal from "common/components/templates/DeactivateUserModal/DeactivateUserModal"
import EditAccountModal from "common/components/templates/EditAccountModal/EditAccountModal"
import ChangePasswordModal from "common/components/templates/ChangePasswordModal/ChangePasswordModal"
import Table from "common/components/atoms/Table"
import { ReactComponent as RightArrow } from "../../common/assets/icons/right-arrow.svg"
import PlusIcon from "../../common/assets/icons/plus.svg"

import Card from "common/components/atoms/Card"
import BarChartView from "common/components/atoms/BarChart/BarChart"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import { useLocation, useNavigate } from "react-router-dom"

const Dashboard = () => {
  const columnHelper = createColumnHelper<UserProfileData>()
  const location = useLocation()
  const navigate = useNavigate()
  const influencerList: UserProfileData[] = [
    {
      _id: "65a8c7ecbca0a772efb572ab",
      userId: "d986efac-fa09-45c4-b374-47c4af24d8d7",
      email: "hemakumar.mohan@willeder.com",
      nickName: "Hemakumar M",
      gender: "Male",
      dob: "2000-02-18",
      province: "mars",
      password: "$2b$10$pcREYNPpsLp0pjpR4gn5u.5R7kwDDRpJbJhcAzcg.IGJHN48qqnde",
      bio: "this is my profile",
      profileImg:
        "Development/d986efac-fa09-45c4-b374-47c4af24d8d7/d81083d5-7d1d-48ce-ade4-01bb8dd99b3c.jpg",
      locale: "en",
      status: false,
      isPublic: true,
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiZDk4NmVmYWMtZmEwOS00NWM0LWIzNzQtNDdjNGFmMjRkOGQ3In0sImlhdCI6MTcxMjExNzUzMiwiZXhwIjoxNzEzODQ1NTMyfQ.-EF5Re23CozsGd6rXG7GM8vtkwIAIW5z1hd6ryoLJo8",
      followerUserId: [],
      deletedAt: null,
      createdAt: "2024-01-18T06:40:44.408Z",
      updatedAt: "2024-04-03T04:12:12.167Z",
      __v: 0,
      blockUsers: [],
      hideBlogPost: ["b69f25d5-bbdd-4a57-9f23-c264ac266eaa"],
      attemptFailureCount: 0,
      blockTime: null,
      hideDailyPost: ["72ba290b-2b66-401e-8ce5-82ce1c850643"],
      isNotification: true,
      followingCount: 2,
      followersCount: 1,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65afbf5891d9c94d562c0929",
      userId: "e69a62b9-d5ab-4e75-8a77-e86be582ba0d",
      email: "hemakumarm72+2@gmail.com",
      nickName: "Hemakumar",
      gender: "Male",
      dob: "18-02-2000",
      province: "tn",
      password: "$2b$10$fDzwdbyESDZJiaBrm5nH1uL5ULC18yEu7UJYD6pV3sOeYXfYzQIeC",
      bio: "I am Hemakumar",
      profileImg: "moto-fellow-default.jpg",
      locale: "en",
      status: false,
      isPublic: true,
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiZTY5YTYyYjktZDVhYi00ZTc1LThhNzctZTg2YmU1ODJiYTBkIn0sImlhdCI6MTcwNjM0OTEzNiwiZXhwIjoxNzA4OTQxMTM2fQ.5QUHbgJihG4zAKbgGgCRetaWvxyfjGmx66fjf7lOQAQ",
      followerUserId: [],
      deletedAt: null,
      createdAt: "2024-01-23T13:30:00.899Z",
      updatedAt: "2024-01-28T08:09:32.689Z",
      __v: 0,
      isNotification: false,
      followingCount: 0,
      followersCount: 0,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65c6262a215bc8b4279e216b",
      userId: "072c27f9-b361-4df5-af0b-f3878c98f85d",
      email: "hemakumarm72+3@gmail.com",
      nickName: "Hemakumar",
      gender: "Male",
      dob: "18-02-2000",
      province: "tn",
      password: "$2b$10$YP2tSvtRBoQge0h01sJ7auOapPUI7jUWpCp93qe5exwSG3qAPSMry",
      bio: "I am Hemakumar",
      profileImg: "moto-fellow-default.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken: null,
      blockUsers: [],
      isNotification: false,
      hideDailyPost: [],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-02-09T13:18:34.472Z",
      updatedAt: "2024-02-09T13:18:34.472Z",
      __v: 0,
      followingCount: 0,
      followersCount: 1,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65c62653215bc8b4279e2178",
      userId: "4a7d7525-b9a8-42a6-b206-22b31f8bb986",
      email: "hemakumarm72+4@gmail.com",
      nickName: "Hemakumar",
      gender: "Male",
      dob: "18-02-2000",
      province: "tn",
      password: "$2b$10$53qXkvcDM7pciUr4cKwDcu1hWLYrM1VcYzYjz7wmBW9f2Wmq1T6Dm",
      bio: "I am Hemakumar",
      profileImg: "moto-fellow-default.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken: null,
      blockUsers: [],
      isNotification: false,
      hideDailyPost: [],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-02-09T13:19:15.409Z",
      updatedAt: "2024-02-09T13:19:15.409Z",
      __v: 0,
      followingCount: 0,
      followersCount: 0,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65c76f59f5b869ab25ba8660",
      userId: "3d13f898-02ab-4a7d-b1d9-fae4db31b900",
      email: "drashti.willeder@gmail.com",
      nickName: "Drashti",
      gender: "Female",
      dob: "Tue, 24 Mar 1998 13:36:00 GMT",
      province: "Yamagata",
      password: "$2b$10$zaKVpea9iwMZtPP61ttJUOnX.8q9L1edMoQEZ7QPCGGsnoa5jR5n2",
      bio: "",
      profileImg:
        "Development/3d13f898-02ab-4a7d-b1d9-fae4db31b900/8b2d8353-515a-41fb-9d4f-010d5049f489.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiM2QxM2Y4OTgtMDJhYi00YTdkLWIxZDktZmFlNGRiMzFiOTAwIn0sImlhdCI6MTcxMjA2NjQ4MywiZXhwIjoxNzE0NjU4NDgzfQ.shKCr12JxPL53LIfcYOCOXj5tLkWka6Fr6ZCqGejGv0",
      blockUsers: ["e69a62b9-d5ab-4e75-8a77-e86be582ba0d"],
      isNotification: false,
      hideDailyPost: [
        "f571cceb-567d-4b3c-b930-4454c8e50f03",
        "f302cc55-13d0-4ad0-8874-bebb2f62a423",
        "80888d71-ce35-47e1-a5b3-ce38415e4de8",
      ],
      hideBlogPost: [
        "ba313107-3065-4e86-aad8-b00901c652a4",
        "b69f25d5-bbdd-4a57-9f23-c264ac266eaa",
        "01befbbb-c806-404f-8d56-4f0bc1530e62",
      ],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-02-10T12:43:05.576Z",
      updatedAt: "2024-04-02T14:01:23.311Z",
      __v: 0,
      followingCount: 4,
      followersCount: 3,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65d58d25682b75d28d3e2a4e",
      userId: "4b7bb16c-c34d-4d58-afb2-de443a8104c4",
      email: "hemakumarm72+5@gmail.com",
      nickName: "Hemakumar",
      gender: "Male",
      dob: "18-02-2000",
      province: "tn",
      password: "$2b$10$pcREYNPpsLp0pjpR4gn5u.5R7kwDDRpJbJhcAzcg.IGJHN48qqnde",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiNGI3YmIxNmMtYzM0ZC00ZDU4LWFmYjItZGU0NDNhODEwNGM0In0sImlhdCI6MTcwODYyNTc0OSwiZXhwIjoxNzExMjE3NzQ5fQ.Etvu5sPh3mjQx2NdfAM6Ypy44M1DIANFquC8xl9BsXA",
      blockUsers: [],
      isNotification: true,
      hideDailyPost: [],
      hideBlogPost: [],
      hideTouring: [],
      profileImg: "moto-fellow-default.jpg",
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-02-21T05:41:57.939Z",
      updatedAt: "2024-03-18T13:49:42.773Z",
      __v: 0,
      followingCount: 0,
      followersCount: 0,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65d76032aeef13f506dae22d",
      userId: "d5ebc949-af44-4c5d-9f4d-83625c641f56",
      email: "drashti.willeder+1@gmail.com",
      nickName: "Drashti",
      gender: "Female",
      dob: "Sat, 26 Sep 1998 14:38:00 GMT",
      province: "Kanagawa",
      password: "$2b$10$a07TOGsXu4OewY0vrTU8nOHi8HbKEbEXDIbHrcreVADCK2x2Oxs3.",
      profileImg: "moto-fellow-default.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken: null,
      blockUsers: [],
      isNotification: true,
      hideDailyPost: [],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-02-22T14:54:42.407Z",
      updatedAt: "2024-03-27T11:29:27.132Z",
      __v: 0,
      bio: "Bio update test\n\nOkay",
      followingCount: 0,
      followersCount: 0,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65e2146c798780fd954e3731",
      userId: "67664806-a654-4c5d-b476-de5e9bb86e81",
      email: "test26demo@gmail.com",
      nickName: "Test",
      gender: "Female",
      dob: "Thu, 23 Mar 1995 17:40:00 GMT",
      province: "Gunma",
      password: "$2b$10$k7dLdLWWEU/xHspcmJt7x.DaRIG/Ofqw.EaxzNYjzKpU8uI3AQi9K",
      profileImg:
        "Development/67664806-a654-4c5d-b476-de5e9bb86e81/3580d845-60de-49b2-b433-e9fdc0be771b.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken: null,
      blockUsers: [],
      isNotification: true,
      hideDailyPost: ["f6463f21-23b4-4fca-aec2-501dfd88c83d"],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-03-01T17:46:20.257Z",
      updatedAt: "2024-03-28T13:40:19.881Z",
      __v: 0,
      bio: "This is meeeee!!! ",
      followingCount: 1,
      followersCount: 1,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65ec25a2fdc60e594e673a62",
      userId: "9069aec1-4fd8-4eea-9864-7b4f5bd4fdaa",
      email: "orirei0829@gmail.com",
      nickName: "Rei",
      gender: "Male",
      dob: "Sat, 29 Aug 1992 09:01:05 GMT",
      province: "Fukuoka",
      password: "$2b$10$lvTsT6acSQIFi0OfAuwKeO4aqvwdvD41aF5Vqs3aFn.gBMbzt9dzC",
      profileImg:
        "Development/9069aec1-4fd8-4eea-9864-7b4f5bd4fdaa/B2257611-DF49-4A14-BE46-70C8FFD52223.jpg",
      locale: "ja",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiOTA2OWFlYzEtNGZkOC00ZWVhLTk4NjQtN2I0ZjViZDRmZGFhIn0sImlhdCI6MTcxMjA2NTkwMiwiZXhwIjoxNzE0NjU3OTAyfQ.ner70uOFoA7hLFWPoNSK5bDf2yUhQgB-09W7ZxF7amw",
      blockUsers: [],
      isNotification: true,
      hideDailyPost: [
        "8f2b1489-7128-489b-8f9a-cfd79784d205",
        "3e7e25a8-f06e-42fb-b503-c50dc6a067a9",
        "bfc6016a-03c9-47fa-bb56-f28015600a25",
        "bfc5dd87-495f-46da-baf2-4535c39b1bfa",
      ],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-03-09T09:02:26.358Z",
      updatedAt: "2024-04-02T14:14:31.750Z",
      __v: 0,
      bio: "Test\n\n🫠🫠🫠\n\n\n\n\nTesth\n",
      followingCount: 1,
      followersCount: 1,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
    {
      _id: "65f567ae5f0459da8de36236",
      userId: "ce2c4e2d-e9e2-490e-818f-e8e6a67589f6",
      email: "masamoto@win-pmc.com",
      nickName: "フルレスMK2",
      gender: "Male",
      dob: "Sat, 06 Jan 2024 15:00:00 GMT",
      province: "Hyogo",
      password: "$2b$10$X6aPopqHgc4.gh3XiMyaJO5HMhriT1GHiAoSDqYG56qLtK/u8bPJO",
      profileImg: "moto-fellow-default.jpg",
      locale: "en",
      status: false,
      attemptFailureCount: 0,
      isPublic: true,
      refreshToken: null,
      blockUsers: [],
      isNotification: true,
      hideDailyPost: [],
      hideBlogPost: [],
      hideTouring: [],
      blockTime: null,
      deletedAt: null,
      createdAt: "2024-03-16T09:34:38.641Z",
      updatedAt: "2024-03-28T13:40:19.881Z",
      __v: 0,
      followingCount: 0,
      followersCount: 1,
      bikeCount: 1,
      lastLogin: "2024-04-03T04:12:12.167Z",
    },
  ]

  const columns = [
    columnHelper.accessor("nickName", {
      header: () => "Nick Name",
      cell: (info) => `${info.row.original.nickName}`,
    }),
    columnHelper.accessor("province", {
      header: () => "Provience",
      cell: (info) => `${info.row.original.province}`,
    }),
    columnHelper.accessor("gender", {
      header: () => "Sex",
      cell: (info) => `${info.row.original.gender}`,
    }),
    columnHelper.accessor("followersCount", {
      header: () => "Followers",
      cell: (info) => `${info.row.original.followersCount}`,
    }),
    columnHelper.accessor("followingCount", {
      header: () => "Following",
      cell: (info) => `${info.row.original.followingCount}`,
    }),
    columnHelper.accessor("lastLogin", {
      header: () => "Last Login",
      cell: (info) => `${info.row.original.lastLogin}`,
    }),
    columnHelper.accessor("bikeCount", {
      header: () => "Registered bikes",
      cell: (info) => `${info.row.original.bikeCount}`,
    }),
    columnHelper.accessor("_id", {
      header: () => "",
      cell: (info) => (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            color: "green",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(`/user-list/profile/${info.row.original._id}`)
          }
        >
          <RightArrow />
        </div>
      ),
    }),
  ]

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "1.56rem",
      }}
    >
      <div
        style={{
          padding: "1.56rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ maxWidth: "17.25rem", width: "100%" }}>
          <Button
            onClick={() => console.log("Filter")}
            icon={PlusIcon}
            iconPosition="left"
            variant="action"
          >
            バイクの追加登録
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1.5rem",
            alignItems: "center",
          }}
        >
          <p>登録ユーザー数</p>
          <p style={{ fontWeight: 700, fontSize: 24 }}>200</p>
        </div>
      </div>
      {location.pathname.includes("/user-list") && (
        <Table
          onFilter={() => {}}
          tableTitle="Contractor list"
          data={influencerList}
          columns={columns}
          onNext={() => console.log("next")}
          onPrev={() => console.log("prev")}
          isLoading={true}
        />
      )}
      {location.pathname === "/bike-list" && (
        <Table
          onFilter={() => {}}
          tableTitle="Contractor list"
          data={influencerList}
          columns={columns}
          onNext={() => console.log("next")}
          onPrev={() => console.log("prev")}
          isLoading={true}
        />
      )}
      {location.pathname === "/insight" && (
        <div
          style={{
            width: "100%",
            maxWidth: "45rem",
            maxHeight: "25rem",
            height: "100%",
            backgroundColor: "white",
            padding: "2rem",
            borderRadius: "1rem",
          }}
        >
          <BarChartView />
        </div>
      )}
    </div>
  )
}

export default Dashboard
